import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getManagerMenu } from './api/menu';
import { loginUser } from './api/login';
import Storage from './views/Storage';

const InternalContext = createContext({});

export const stylesToast = {
  autoClose: 3000,
  isLoading: false,
  draggable: true,
  closeButton: true,
  closeOnClick: true,
};

export const StateProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [menus, setMenus] = useState([]);
  const [flatMenus, setFlatMenus] = useState([]);

  const history = useHistory();

  const logout = () => {
    setMenus([]);
    setFlatMenus([]);
  };

  const loginUserManager = async ({
    nameAdm,
    userLogin,
    password,
    tokenCaptcha,
  }) => {
    const toastId = toast.loading('Realizando login', {
      position: 'top-center',
    });
    return loginUser(nameAdm, userLogin, password, tokenCaptcha)
      .then(data => {
        if (data.status === 200) {
          const {
            data: {
              token,
              merchantId,
              managerProfile,
              login,
              merchantConfigs,
              actions,
              user,
              chatToken,
              stores,
              groups,
              ...rest
            },
          } = data;

          Sentry.configureScope(scope => {
            scope.setTag('merchant', nameAdm);
            scope.setUser({ username: `[${nameAdm}] - ${userLogin}` });
          });

          const blocked = merchantConfigs.find(
            ({ key, value }) => key === 'BLOCKED' && value === '1'
          );

          if (blocked) {
            Storage.clear();
            Storage.setItem('blocked', true);
            history.push('error');
            return;
          }
          Storage.clear();
          if (rest.tomtomJWT) {
            Storage.setItem('tomtomJWT', rest.tomtomJWT);
          }
          Storage.setItem('user', { ...user, storeGroups: groups, groups });
          Storage.setItem(
            'chatToken',
            chatToken || '4a877225-46af-4001-9e54-a97d214b6f06'
          );
          Storage.setItem('blocked', false);
          Storage.setItem('token', token);
          Storage.setItem('nameAdm', nameAdm);
          Storage.setItem(
            'profileId',
            managerProfile.menus.find(
              ({ managerMenuId: id }) => id === 4 || id === 49
            )
              ? 1
              : 99
          );
          Storage.setItem('merchantId', merchantId);
          Storage.setItem('managerProfile', managerProfile);
          Storage.setItem('actions', actions);
          Storage.setItem('login', login);

          toast.update(toastId, {
            render: `Bem vindo, ${user.name}!`,
            type: 'success',
            ...stylesToast,
          });

          if (managerProfile.type === 'CallCenter') {
            history.push('callcenter');
          } else {
            history.push('/');
          }
        } else {
          Sentry.captureException(data);
          toast.error('Usuário ou senha incorretos!', {
            position: 'top-center',
            ...stylesToast,
          });
        }
      })
      .catch(err => {
        Sentry.captureException(err);
        toast.update(toastId, {
          render: 'Usuário ou senha incorretos!',
          type: 'error',
          ...stylesToast,
        });
      });
  };

  const load = useCallback(async () => {
    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))?.value
      : null;
    if (!user) {
      setFlatMenus([]);
      setMenus([]);
      return;
    }
    const managerProfile = Storage.getItem('managerProfile');
    const allMenus = await getManagerMenu();
    const isAnotaAi = process.env.source === 'ANOTAAI';
    console.log('# allMenus: ', JSON.stringify(allMenus));
    console.log(
      '# managerProfile.menus: ',
      JSON.stringify(managerProfile.menus)
    );

    let menusData = [...allMenus];
    if (isAnotaAi) {
      if (
        !managerProfile.menus.find(
          item => item.accessUrl.indexOf('/stock') !== -1
        )
      ) {
        console.log('# Não tem stock');
        menusData = menusData.filter(menu => {
          if (menu.url === '/parentroute') {
            return menu.children.find(
              subMenu => subMenu.url.indexOf('/stock') === -1
            );
          }
          if (menu.url.indexOf('/stock') === -1) {
            return true;
          }
          if (menu.url.indexOf('/register') === -1) {
            return true;
          }
          if (menu.url.indexOf('/nfe') === -1) {
            return true;
          }
          return false;
        });

        // filter(
        //   ({ url, children: subMenus }) =>
        //     (url.indexOf('/stock') === -1 &&
        //       url.indexOf('/register') === -1 &&
        //       url.indexOf('/nfe') === -1) ||
        //     subMenus.find(subMenu => subMenu.url.indexOf('/stock') === -1)
        // );
      }
      if (
        !managerProfile.menus.find(
          item => item.accessUrl.indexOf('/fiscal') !== -1
        )
      ) {
        console.log('# Não tem fiscal');
        menusData = menusData.filter(menu => {
          if (menu.url === '/parentroute') {
            return menu.children.find(
              subMenu => subMenu.url.indexOf('/fiscal') === -1
            );
          }
          if (menu.url.indexOf('/fiscal') === -1) {
            return true;
          }
          return false;
        });
      }
      if (
        !managerProfile.menus.find(
          item => item.accessUrl.indexOf('/finance') !== -1
        )
      ) {
        console.log('# Não tem finance');
        menusData = menusData.filter(menu => {
          if (menu.url === '/parentroute') {
            return menu.children.find(
              subMenu => subMenu.url.indexOf('/finance') === -1
            );
          }
          if (menu.url.indexOf('/finance') === -1) {
            return true;
          }
          return false;
        });
      }
    }
    console.log('# menusData     : ', menusData);
    if ((user.id === 170 && user.login === 'master') || user.id === 5953) {
      menusData.push({
        id: 999,
        name: 'Master',
        url: '/',
        icon: 'fa fa-unlock',
        children: [
          {
            icon: 'fa fa-store',
            name: 'Notificar PDV',
            parentManagerMenuId: 999,
            url: '/notify-pdv-messages',
          },
          {
            icon: 'fa fa-store',
            name: 'KDS',
            parentManagerMenuId: 999,
            url: '/kds',
          },
          {
            icon: 'fa fa-store',
            name: 'Configurar Loja',
            parentManagerMenuId: 999,
            url: '/store/configs',
          },
          {
            name: 'Configurar Pagamentos Digitais',
            parentManagerMenuId: 999,
            url: '/store/paymentintegration',
            icon: 'fa fa-handshake',
          },
          {
            name: 'Configuração Integrações',
            parentManagerMenuId: 999,
            url: '/store/integrations',
            icon: 'fa fa-handshake',
          },
          {
            name: 'Configuração de computadores',
            parentManagerMenuId: 999,
            url: '/store/devices',
            icon: 'fa fa-laptop',
          },
          {
            name: 'Dre Novo',
            parentManagerMenuId: 999,
            url: '/reports/new/dre',
            icon: 'fa fa-industry',
          },
          {
            icon: 'fa fa-file',
            name: 'Série de Nota',
            parentManagerMenuId: 999,
            url: '/fiscal/serie',
          },
          {
            icon: 'fa fa-file',
            name: 'Relatorio por Loja Teste',
            parentManagerMenuId: 999,
            url: '/opreports/store/new',
          },
          {
            icon: 'fa fa-file',
            name: 'Relatorio por pagamento Teste',
            parentManagerMenuId: 999,
            url: '/opreports/ordes/payments/new',
          },
          {
            icon: 'fa fa-desktop',
            name: 'Configurações Teste', // temporário
            parentManagerMenuId: 999,
            url: '/salespoint/ui',
          },
        ],
        tabs: [],
      });
    }

    menusData.forEach(menu => {
      flatMenus.push(menu);
      menu?.tabs?.forEach(tab => flatMenus.push(tab));
      if (menu.children) {
        menu.children.forEach(child => {
          child?.tabs?.forEach(tab => flatMenus.push(tab));
          flatMenus.push(child);
        });
      }
    });
    setMenus(menusData);
    setFlatMenus(flatMenus);
  }, []);

  useEffect(() => {
    load();
  }, []);

  return (
    <InternalContext.Provider
      value={{
        menus,
        flatMenus,
        run,
        setRun,
        load,
        logout,
        loginUserManager,
      }}
    >
      {children}
    </InternalContext.Provider>
  );
};

export const useInternalContext = () => useContext(InternalContext);

const allMenus = [
  {
    id: 124,
    name: 'Notas Fiscais',
    url: '/parentroute',
    icon: 'fa fa-file',
    urlVideo: null,
    urlBase: null,
    tabs: [],
    children: [
      {
        id: 125,
        name: 'Exportar Notas',
        parentManagerMenuId: 124,
        url: '/fiscal/export',
        icon: 'fa fa-file',
        urlVideo: 'https://youtu.be/hZ0m9JGXpMU?si=cyp6x9ndvWJyrFiS',
        urlBase:
          'https://opdv.tomticket.com/kb/notas-fiscais/exportar-xml-gerencial',
        tabs: [],
      },
      {
        id: 126,
        name: 'Emitir Notas',
        parentManagerMenuId: 124,
        url: '/fiscal/create',
        icon: 'fa fa-file',
        urlVideo: null,
        urlBase: null,
        tabs: [],
      },
    ],
  },
  {
    id: 5,
    name: 'Financeiro',
    url: '/parentroute',
    icon: 'icon-diamond',
    urlVideo: null,
    urlBase: null,
    tabs: [],
    children: [
      {
        id: 25,
        name: 'Formas de pagamento',
        parentManagerMenuId: 5,
        url: '/finance/payments',
        icon: 'icon-credit-card',
        urlVideo: 'https://youtu.be/z90KKXJ0IE8',
        urlBase:
          'https://opdv.tomticket.com/kb/formas-de-pagamento/formas-de-pagamento',
        tabs: [],
      },
      {
        id: 31,
        name: 'Categorias tributárias',
        parentManagerMenuId: 5,
        url: '/finance/tributes',
        icon: 'fa fa-legal',
        urlVideo: 'https://youtu.be/ADHE-BrE9Ng',
        urlBase:
          'https://opdv.tomticket.com/kb/categorias-tributarias/categorias-tributarias',
        tabs: [
          {
            id: 176,
            parentManagerMenuId: 31,
            urlVideo: null,
            title: 'Códigos Fiscais',
            url: '/finance/tributes/config',
            icon: null,
          },
          {
            id: 177,
            parentManagerMenuId: 31,
            urlVideo: null,
            title: 'Produtos',
            url: '/finance/tributes/product',
            icon: null,
          },
        ],
      },
      {
        id: 34,
        name: 'Conciliação de Caixa',
        parentManagerMenuId: 5,
        url: '/finance/workshift',
        icon: 'icon-basket-loaded',
        urlVideo: 'https://youtu.be/GlB3L-Vnt7I',
        urlBase:
          'https://opdv.tomticket.com/kb/conciliacao-de-caixa/conciliacao-de-caixa',
        tabs: [],
      },
      {
        id: 77,
        name: 'Relatórios',
        parentManagerMenuId: 5,
        url: '/finance/report',
        icon: 'fa fa-bookmark',
        urlVideo: null,
        urlBase: null,
        tabs: [
          {
            id: 78,
            parentManagerMenuId: 77,
            urlVideo: 'https://youtu.be/h9Vy2kpLlqI',
            title: 'Saldo Diário',
            url: '/finance/report/basic',
            icon: null,
          },
          {
            id: 79,
            parentManagerMenuId: 77,
            urlVideo: 'https://youtu.be/WMgnmNTKeTw',
            title: 'Saldo Diário Detalhe',
            url: '/finance/report/cashflow',
            icon: null,
          },
          {
            id: 92,
            parentManagerMenuId: 77,
            urlVideo: 'https://youtu.be/1J3BvgH96sc',
            title: 'Conferência de Numerários',
            url: '/finance/report/cashdaily',
            icon: null,
          },
          {
            id: 111,
            parentManagerMenuId: 77,
            urlVideo: null,
            title: 'Sangrias e Suprimentos',
            url: '/finance/report/withdraw',
            icon: '',
          },
          {
            id: 156,
            parentManagerMenuId: 77,
            urlVideo: null,
            title: 'Tranferência Conta',
            url: '/finance/report/bank',
            icon: null,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Estoque',
    url: '/parentroute',
    icon: 'fa fa-book',
    urlVideo: null,
    urlBase: null,
    tabs: [],
    children: [
      {
        id: 37,
        name: 'Estoque Atual',
        parentManagerMenuId: 3,
        url: '/stock',
        icon: 'fa-solid fa-boxes-stacked',
        urlVideo: 'https://youtu.be/MSxpJJqz2NQ',
        urlBase: 'https://opdv.tomticket.com/kb/estoque/gerenciar-estoque',
        tabs: [],
      },
      {
        id: 36,
        name: 'Ficha Técnica',
        parentManagerMenuId: 3,
        url: '/stock/techinal',
        icon: 'fa fa-address-card',
        urlVideo: 'https://www.youtube.com/watch?v=JiI2D-ZSSJ8',
        urlBase: null,
        tabs: [],
      },
      {
        id: 63,
        name: 'Ajustes de Estoque',
        parentManagerMenuId: 3,
        url: '/stock/movements',
        icon: 'fa fa-asterisk',
        urlVideo: 'https://youtu.be/x42i77IfjzM',
        urlBase: 'https://opdv.tomticket.com/kb/estoque/ajustar-estoque',
        tabs: [],
      },
      {
        id: 64,
        name: 'Relatórios',
        parentManagerMenuId: 3,
        url: '/stock/report',
        icon: 'fa fa-bookmark',
        urlVideo: 'https://youtu.be/D7oZQs2jl4M',
        urlBase: null,
        tabs: [
          {
            id: 65,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Movimentação Analítica',
            url: '/stock/report/balance',
            icon: null,
          },
          {
            id: 66,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Extrato Detalhado',
            url: '/stock/report/balance/detail',
            icon: null,
          },
          {
            id: 67,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Conferência de Inventários',
            url: '/stock/report/inventary/conference',
            icon: null,
          },
          {
            id: 68,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Produtos de Estoque',
            url: '/stock/report/products',
            icon: null,
          },
          {
            id: 120,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Fichas técnicas resumo',
            url: '/stock/report/productsheet',
            icon: null,
          },
          {
            id: 123,
            parentManagerMenuId: 64,
            urlVideo: null,
            title: 'Consumo de Insumos',
            url: '/stock/report/productsused',
            icon: null,
          },
        ],
      },
      {
        id: 118,
        name: 'Estoque mínimo',
        parentManagerMenuId: 3,
        url: '/stock/config',
        icon: 'fa fa-cubes',
        urlVideo: 'https://youtu.be/IGTTxsRzKq0',
        urlBase: null,
        tabs: [],
      },
      {
        id: 128,
        name: 'Configuração de Custo',
        parentManagerMenuId: 3,
        url: '/stock/cost',
        icon: 'fa fa-cubes',
        urlVideo: 'https://youtu.be/cOhUUhCiOok',
        urlBase: null,
        tabs: [],
      },
      {
        id: 174,
        name: 'Produtos de venda',
        parentManagerMenuId: 3,
        url: '/stock/sales/products',
        icon: 'icon-grid',
        urlVideo: null,
        urlBase: null,
        tabs: [],
      },
      {
        id: 173,
        name: 'Insumos de estoque',
        parentManagerMenuId: 3,
        url: '/stock/products',
        icon: 'icon-grid',
        urlVideo: null,
        urlBase: null,
        tabs: [],
      },
      {
        id: 143,
        name: 'Categorias de Estoque',
        parentManagerMenuId: 3,
        url: '/stock/categories',
        icon: 'icon-grid',
        urlVideo: 'https://www.youtube.com/watch?v=-1C1ak1zZLM',
        urlBase: null,
        tabs: [],
      },
    ],
  },
  {
    id: 11,
    name: 'Loja',
    url: '/parentroute',
    icon: 'fa fa-flag-checkered',
    urlVideo: null,
    urlBase: null,
    tabs: [],
    children: [
      {
        id: 101,
        name: 'Configuração NFCe',
        parentManagerMenuId: 11,
        url: '/store/nfce',
        icon: 'fa fa-file-code',
        urlVideo: 'https://youtu.be/94U9j3qZY3E',
        urlBase:
          'https://opdv.tomticket.com/kb/configuracao-de-nfce/configuracao-nfce',
        tabs: [],
      },
    ],
  },
];
const menuData = [
  { managerMenuId: 36, accessUrl: '/stock/techinal' },
  { managerMenuId: 37, accessUrl: '/stock' },
  { managerMenuId: 63, accessUrl: '/stock/movements' },
  { managerMenuId: 64, accessUrl: '/stock/report' },
  { managerMenuId: 101, accessUrl: '/store/nfce' },
  { managerMenuId: 118, accessUrl: '/stock/config' },
  { managerMenuId: 128, accessUrl: '/stock/cost' },
  { managerMenuId: 143, accessUrl: '/stock/categories' },
  { managerMenuId: 173, accessUrl: '/stock/products' },
  { managerMenuId: 174, accessUrl: '/stock/sales/products' },
];

// if (!menuData.find(item => item.accessUrl = )){
//   allMenus = allMenus.find(item => )
// }
