import { generateRequest } from './index';

export const loginUser = async (nameAdm, login, password, tokenCaptcha) => {
  const request = generateRequest();
  const isAnotaAi = process.env.source === 'ANOTAAI';
  if (isAnotaAi) {
    return request.post('login/anotaai/gerencial', { login, password });
  }
  return request.post('login', { nameAdm, login, password, tokenCaptcha });
};

/**
 *
 * @param {number} merchantId
 * @returns AxiosRequest
 */
export const changeMerchant = async merchantId => {
  return generateRequest().post(`/login/change/${merchantId}`);
};

export const loginUserAdmin = async ({ nameAdm, token }) => {
  const request = generateRequest();
  return request.post('login/admin', { nameAdm, token });
};

export const loginAnotaai = async ({ jwt }) => {
  const request = generateRequest();
  const resp = await request.get(`login/anotaai?jwt=${jwt}`);
  console.log('# resp.status: ', resp.status);
  if (resp.status === 200) {
    return resp.data;
  }
  return null;
};

export const verifyAction = async ({ login, password, actionId }) => {
  const request = generateRequest();
  const resp = await request.post(`action/verify`, {
    login,
    password,
    actionId,
  });
  if (resp.status === 200) {
    return resp.data;
  }
  return null;
};
