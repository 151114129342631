import axios from 'axios';
import Storage from '../views/Storage';

const { version } = require('../../package.json');

const token = Storage.getItem('token');

const apiUrl = {
  DEV: 'http://127.0.0.1:8082/',
  DEV2: 'http://localhost:6002/',
  HOMOLOG: 'https://api-gerencial-homolog.opdv.com.br/',
  PRODUCTION: 'https://api-gerencial.opdv.com.br/',
};

const anotaAIUrl = {
  DEV: 'https://staging-webhook-integration-orders.anota-ai.com',
  HOMOLOG: 'https://staging-webhook-integration-orders.anota-ai.com',
  PRODUCTION: 'https://webhook-integration-orders-api.prod.anota.ai',
};
const anotaAiApiKEY = {
  DEV: '7c847ed7-3a23-40b9-bd82-9fe8935bc5a4',
  HOMOLOG: '7c847ed7-3a23-40b9-bd82-9fe8935bc5a4',
  PRODUCTION: 'de4fcc02-d194-421b-9572-d717af921303',
};

export const baseURL = apiUrl[process.env.ENVIRONMENT];

export const baseANOTAAiURL = anotaAIUrl[process.env.ENVIRONMENT];

const anotaAiInstance = axios.create({
  baseURL: baseANOTAAiURL,
  timeout: 15 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': anotaAiApiKEY[process.env.ENVIRONMENT],
  },
});

export const postAnotaAiEvent = async (eventName, parameters) => {
  const isAnotaAi = await Storage.getItem('isAnotaAi');
  if (!isAnotaAi) {
    return;
  }
  await anotaAiInstance.post('/opdv/send-event', {
    eventName,
    ...parameters,
  });
};

const instance = axios.create({
  baseURL,
  timeout: 15 * 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    'x-access-token': token,
  },
});

export const generateBucketRequest = async (
  contentType = 'application/json'
) => {
  const id = await Storage.getItem('merchantId');
  const headers = {
    'Content-Type': contentType,
  };
  return axios.create({
    baseURL: `https://delivery.opdv.com.br/${id}/`,
    timeout: 15 * 60 * 1000,
    headers,
  });
};

export const generateS3Request = async () => {
  const headers = {
    'Content-Type': 'application/json',
    'X-Api-Key': '3pM5Ova9W3hae7W2cPzC4sTUNbY1VOD2TBbCcAej',
  };
  return axios.create({
    baseURL: `https://neauo6xui1.execute-api.us-east-1.amazonaws.com/default`,
    timeout: 15 * 60 * 1000,
    headers,
  });
};

export const generateRequest = (
  contentType = 'application/json',
  responseType = 'json'
) => {
  const tokenUser = Storage.getItem('token');
  const headers = {
    'Content-Type': contentType,
    'x-access-token': tokenUser,
    'x-version': version,
  };

  const instanceRequest = axios.create({
    baseURL,
    timeout: 15 * 60 * 1000,
    headers,
    responseType, // Define o responseType com base no parâmetro
  });

  instanceRequest.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return instanceRequest;
};

export default instance;
